import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";
import styled from "styled-components";

const Feedback = () => {
  const { allFeedbackInfo } = useStaticQuery(graphql`
    query FeedbackInfo {
      allFeedbackInfo {
        nodes {
          id
          name
          title
          description
        }
      }
    }
  `);

  const feedbackInfo = allFeedbackInfo.nodes;

  return (
    <FeedbackStyles>
      {feedbackInfo.map(info => (
          <FeedbackInner key={info.id}>
            <p>
              {info.description}
            </p>
            <FeedbackTop>
              <span className="quote quote__right"><FaQuoteRight /></span>
              <div>
                <h3>{info.name}</h3>
                <span>{info.title}</span>
              </div>
            </FeedbackTop>
          </FeedbackInner>
      ))}
    </FeedbackStyles>
  )
}

Feedback.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

// Component styles
const FeedbackStyles = styled.div`
  font-family: 'Inter', sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  p {
    line-height: 1.8;
    font-size: 17px;
  }
  .quote {
    &__right {
      padding-left: 18px;
      padding-right: 18px;
    }
    &__left {
      padding-right: 8px;
    }
  }
`;

const FeedbackInner = styled.div`
  padding: 20px 25px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px 0px;
  border-radius: 10px;
`

const FeedbackTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  line-height: 24px;
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #6b6f76;
  }
  span {
    font-weight: 500;
    line-height: 1.5;
    font-size: 13px;
  }
`;

export default Feedback;