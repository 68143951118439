import React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from "styled-components";
import theme from "../../config/theme";

const Services = () => {
  const { allService } = useStaticQuery(graphql`
    query Service {
      allService {
        nodes {
          id
          name
          description
          path
        }
      }
    }
  `);

  const myServices = allService.nodes;

  return (
    <ServicesWrapper>
      {myServices.map(service => (
        <div key={service.id}>
          <Link to={service.path}>
          <ServicesWrapperInner>
              <h3>{service.name}</h3>
              <p>{service.description}</p>
          </ServicesWrapperInner>
          </Link>
        </div>
      ))}
    </ServicesWrapper>
  )
}

Services.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    path: PropTypes.string,
  }),
};

//Component styles
const ServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
  grid-column-gap: 20px;
  justify-content: center;
  line-height: 24px;
  h3 {
    font-size: 16px;
    font-weight: 700;
  }
  a {
    color: ${theme.colors.black};
  }
`;

const ServicesWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 20px 25px;
  border-radius: 10px;
  color: ${theme.colors.black};
  background: rgb(240, 243, 249);
  box-shadow: rgba(0,0,0,0.09) 0px 3px 12px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out 0s;
  &:hover {
    transform: scale(1.03);
  }
`;

export default Services;