import React from "react";
import styled from "styled-components";
import theme from "../../config/theme";
import {graphql, useStaticQuery} from "gatsby";

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      me: file(relativePath: { eq: "me.webp" }) {
        childImageSharp {
          resize(width: 300) {
            src
          }
        }
      }
    }
 `);

  return (
    <IntroStyles>
      <ImageWrapper>
        <img
          src={data.me.childImageSharp.resize.src}
          alt="Creativehubspace"
        />
      </ImageWrapper>
      <ContentWrapper>
        <h2>Hey, I'm Dennis!</h2>
        <p>Websites and Ecommerce Developer.</p>
        <p>
          — Sweden
        </p>
        <p className="contact"><a href="https://www.linkedin.com/in/mathengedennis" target="_blank">Contact me!</a></p>
      </ContentWrapper>
    </IntroStyles>
  )
}

const IntroStyles = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 0 30px;
  color: ${theme.colors.black};
  h2 {
    @media (min-width: ${theme.breakpoints.m}) {
      font-size: 56px;
    }
  }
  p {
    line-height: 1.5;
    &.contact {
      margin-top: 20px;
    }
  }
  a {
    color: ${theme.colors.white};
    box-shadow: 0 10px 20px -10px ${theme.colors.black};
    background-color: ${theme.colors.black};
    padding: 12px 20px;
    border-radius: 999px;
  }
`;

const ImageWrapper = styled.div`
  width: 45%;
  padding: 20px 15px;
`;

const ContentWrapper = styled.div`
  width: 45%;
  padding: 20px 15px;
`;

export default Intro;