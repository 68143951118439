import React from "react";
import SEO from "../components/Seo";
import Layout from "../components/Layout";
import styled from "styled-components";
import Feedback from "../components/Feedback";
import Services from "../components/Services";
import Intro from "../components/Intro";
import theme from "../../config/theme";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <WrapperIntro>
        <WrapperContent>
          <Intro />
        </WrapperContent>
      </WrapperIntro>
      <Wrapper>
        <WrapperContent>
          <h2 className="section__header">Services</h2>
          <Services />
        </WrapperContent>
      </Wrapper>
      <Wrapper primary>
        <WrapperContent>
          <h2 className="section__header">Client feedback</h2>
          <Feedback />
        </WrapperContent>
      </Wrapper>
    </Layout>
  )
}

// Component styles
const WrapperIntro = styled.section`
`;
const Wrapper = styled.section`
  padding: 20px 20px 40px;
`;

const WrapperContent = styled.div`
  .section__header {
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 2.2;
    margin-bottom: 10px;
    padding: 0;
    @media (max-width: ${theme.breakpoints.xs}) {
      font-size: 20px;
      line-height: 1.5;
    }
  }
`;

export default IndexPage;
